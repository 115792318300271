<template>
  <div class="view pa24">
    <el-form :model="form" label-width="120px">
      <el-form-item label="菜单状态:">
        <el-switch
          v-model="form.statusLeft"
          active-text="开启"
          inactive-text="关闭"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="菜单名称(左侧):">
        <div class="d-flex">
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="form.nameLeft"
            style="width: 200px"
            class="mr20"
            maxlength="4"
          ></el-input>
          <p class="cA3">注：该文案原为需求广场</p>
        </div>
      </el-form-item>
      <el-form-item label="顶部轮播图:">
        <div class="d-flex">
          <el-upload
            action="https://businesscard.lbyqm.com/file/uploadObjectOSS"
            list-type="picture"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :file-list="form.fileListLeft"
            :auto-upload="true"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*422像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M，最少上传1张最多可上传6张
            </div>
          </el-upload>
        </div>
          
      </el-form-item>

      <el-divider></el-divider>

      <el-form-item label="菜单状态:">
        <el-switch
          v-model="form.statusRight"
          active-text="开启"
          inactive-text="关闭"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="菜单名称(右侧):">
        <div class="d-flex">
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="form.nameRight"
            style="width: 200px"
            class="mr20"
            maxlength="4"
          ></el-input>
          <p class="cA3">注：该文案原为产品中心</p>
        </div>
      </el-form-item>
      <el-form-item label="顶部轮播图:">
        <el-upload
          action="https://businesscard.lbyqm.com/file/uploadObjectOSS"
          list-type="picture"
          :on-remove="handleRemoveRight"
          :on-success="handleAvatarSuccessRight"
          :before-upload="beforeAvatarUploadRight"
          :file-list="form.fileListRight"
          :auto-upload="true"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            上传图片的最佳尺寸：750像素*422像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M，最少上传1张最多可上传6张
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button
          style="background-color: #51cdcb; color: white"
          @click="saveSetting"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  updateCustomizeSetting,
  queryCustomizeSetting,
} from "@/api/addedServices";
export default {
  data() {
    return {
      form: {
        fileListRight: [],
        rightImg: "",
        leftImage: "",
        nameLeft: "",
        statusLeft: false,
        fileListLeft: [],
        statusRight: false,
        nameRight: "",
      },
    };
  },
  created() {
    this.queryCustomizeSetting();
  },
  methods: {
    handleRemove(file, fileList) {
      const index = this.form.fileListLeft.findIndex(
        (item) => item.url == file.url
      );
      if (index > -1) {
        this.form.fileListLeft.splice(index, 1);
      }
    },
    handleRemoveRight(file) {
      const index = this.form.fileListRight.findIndex(
        (item) => item.url == file.url
      );
      if (index > -1) {
        this.form.fileListRight.splice(index, 1);
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.code == 200) {
        file.url = `https://lan8-e-business.oss-cn-hangzhou.aliyuncs.com/${res.data}`;
        this.form.fileListLeft.push(file);
      }
    },
    handleAvatarSuccessRight(res, file) {
      if (res.code == 200) {
        file.url = `https://lan8-e-business.oss-cn-hangzhou.aliyuncs.com/${res.data}`;
        this.form.fileListRight.push(file);
      }
    },
    beforeAvatarUploadRight(file) {
      if (this.form.fileListRight.length >= 6) {
        this.$message.msg("最少上传1张最多可上传6张");
        return false;
      } else {
        const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error("上传头像图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      }
    },
    beforeAvatarUpload(file) {
      if (this.form.fileListLeft.length >= 6) {
        this.$message.msg("最少上传1张最多可上传6张");
        return false;
      } else {
        const isJPG = file.type === "image/jpeg";
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error("上传头像图片只能是 JPG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      }
    },
    async saveSetting() {
      if (this.form.fileListLeft.length == 0) {
        this.$message.msg("左侧最少上传1张最多可上传6张");
        return;
      }
      if (this.form.fileListRight.length == 0) {
        this.$message.msg("右侧侧最少上传1张最多可上传6张");
        return;
      }
      this.form.rightImg = JSON.stringify(
        this.form.fileListRight.map((item) => {
          return item.url;
        })
      );
      this.form.leftImage = JSON.stringify(
        this.form.fileListLeft.map((item) => {
          return item.url;
        })
      );
      try {
        await updateCustomizeSetting(this.form);
        this.$message({
          message:'保存成功',
          type:'success'
        })
      } catch (error) {
        console.log(error);
      }
    },
    async queryCustomizeSetting() {
      const result = await queryCustomizeSetting(
        { pageSize: 0 },
        { showLoading: true }
      );
      const { data } = result;
      if (data && data.settingId) {

        this.form = data;
        if (data.leftImage) {
          const left = JSON.parse(data.leftImage);
          this.form.fileListLeft = [];
          left.forEach((item, index) => {
            this.form.fileListLeft.push({ uid: index, url: item });
          });
          this.form.fileListLeft = this.form.fileListLeft;
        }
        if (data.rightImg) {
          const right = JSON.parse(data.rightImg);
          this.form.fileListRight = [];
          right.forEach((item, index) => {
            this.form.fileListRight.push({ uid: index, url: item });
          });
          this.form.fileListRight = this.form.fileListRight;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload-list__item{
  width: 100px;
  display: inline-block;
  margin-right: 10px;
}
</style>